import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const mockStaff = {
  id: 'staff-1',
  name: 'Staff Admin',
  email: 'staff@example.com',
  role: 'staff',
  avatar: null,
};

const mockClient = {
  id: 'client-1',
  name: 'Client User',
  email: 'client@example.com',
  role: 'client',
  avatar: null,
};

const mockUser = {
  id: 'user-1',
  name: 'John Doe',
  email: 'john@example.com',
  role: 'user',
  avatar: null,
};

const mockAgent = {
  id: 'agent-1',
  name: 'Agent User',
  email: 'agent@example.com',
  role: 'agent',
  avatar: null,
};

const useAuthStore = create(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      loading: false,
      error: null,

      login: async (email, password, role = 'user') => {
        set({ loading: true, error: null });
        try {
          await new Promise((resolve) => setTimeout(resolve, 1000));

          switch (role) {
            case 'staff':
              set({ user: mockStaff, isAuthenticated: true, loading: false });
              return true;
            case 'client':
              set({ user: mockClient, isAuthenticated: true, loading: false });
              return true;
            case 'agent':
              set({ user: mockAgent, isAuthenticated: true, loading: false });
              return true;
            default:
              set({ user: mockUser, isAuthenticated: true, loading: false });
              return true;
          }
        } catch (error) {
          set({ error: error.message, loading: false });
          return false;
        }
      },

      register: async (userData, role = 'user') => {
        set({ loading: true, error: null });
        try {
          switch (role) {
            case 'staff':
              set({ user: mockStaff, isAuthenticated: true, loading: false });
              return true;
            case 'client':
              set({ user: mockClient, isAuthenticated: true, loading: false });
              return true;
            case 'agent':
              set({ user: mockAgent, isAuthenticated: true, loading: false });
              return true;
            default:
              set({ user: mockUser, isAuthenticated: true, loading: false });
              return true;
          }
        } catch (error) {
          set({ error: error.message, loading: false });
          return false;
        }
      },

      logout: () => {
        set({ user: null, isAuthenticated: false, error: null });
      },

      updateProfile: async (profileData) => {
        set({ loading: true, error: null });
        try {
          set((state) => ({
            user: { ...state.user, ...profileData },
            loading: false,
          }));
          return true;
        } catch (error) {
          set({ error: error.message, loading: false });
          return false;
        }
      },

      clearError: () => set({ error: null }),
    }),
    {
      name: 'auth-storage',
      getStorage: () => localStorage,
    }
  )
);

export default useAuthStore;
