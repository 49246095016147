import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Tabs,
  Tab,
  Avatar,
} from '@mui/material';
import {
  Person,
  FitnessCenter,
  Psychology,
  Message,
} from '@mui/icons-material';
import useAuthStore from '../../stores/useAuthStore';
import AgentProfile from '../../components/agent/AgentProfile';
// import AssignedFitnessClients from '../../components/agent/AssignedFitnessClients';
// import AssignedSpecialistClients from '../../components/agent/AssignedSpecialistClients';
import AgentMessages from '../../components/agent/AgentMessages';
import UserManagement from '../../components/agent/UserManagement';

const AgentDashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { user } = useAuthStore();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return <AgentProfile />;
      case 1:
        return <AgentMessages />;
      case 2:
        return <UserManagement />;
      // case 2:
      //   return <AssignedFitnessClients />;
      // case 3:
      //   return <AssignedSpecialistClients />;
      default:
        return <AgentProfile />;
    }
  };

  return (
    <Box sx={{ minHeight: 'calc(100vh - 64px)', bgcolor: '#f5f5f5', py: 4 }}>
      <Container maxWidth='lg'>
        <Grid container spacing={3}>
          {/* Agent Info Header */}
          <Grid item xs={12}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Avatar
                  sx={{ width: 64, height: 64, bgcolor: 'secondary.main' }}
                >
                  {user?.name?.charAt(0) || 'A'}
                </Avatar>
                <Box>
                  <Typography variant='h5' sx={{ fontWeight: 600 }}>
                    {user?.name || 'Agent'}
                  </Typography>
                  <Typography variant='body1' color='text.secondary'>
                    {user?.email}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Navigation Tabs */}
          <Grid item xs={12}>
            <Paper sx={{ mb: 3 }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant='scrollable'
                scrollButtons='auto'
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <Tab icon={<Person />} label='Profile' />
                <Tab icon={<Message />} label='Messages' />
                <Tab icon={<Person />} label='User Management' />
                {/* <Tab icon={<FitnessCenter />} label='Fitness Clients' />
                <Tab icon={<Psychology />} label='Specialist Clients' /> */}
              </Tabs>
            </Paper>
          </Grid>

          {/* Content Area */}
          <Grid item xs={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AgentDashboard;
