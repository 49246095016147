/* eslint-disable quotes */
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';

// Auth
import ProtectedRoute from './components/auth/ProtectedRoute';

// Common pages
import Hero from './components/common/Hero';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import AboutUs from './pages/common/AboutUs';
import PrivacyPolicy from './pages/common/PrivacyPolicy';
import TermsOfUse from './pages/common/TermsOfUse';
import AboutFitness from './pages/common/AboutFitness';
import AboutSpecialist from './pages/common/AboutSpecialist';

// User pages
import UserLoginForm from './components/user/LoginForm';
import UserRegisterForm from './components/user/RegisterForm';
import UserForgotPasswordForm from './components/user/ForgotPasswordForm';
import UserDashboard from './pages/user/Dashboard';
import UserFitnessListing from './pages/user/FitnessListing';
import UserFitnessDetail from './pages/user/FitnessDetail';
import UserSpecialistListing from './pages/user/SpecialistListing';
import UserSpecialistDetail from './pages/user/SpecialistDetail';

// Staff pages
import StaffLoginForm from './components/staff/LoginForm';
import StaffRegisterForm from './components/staff/RegisterForm';
import StaffForgotPasswordForm from './components/staff/ForgotPasswordForm';
import StaffDashboard from './pages/staff/Dashboard';
import StaffFitnessListing from './pages/staff/FitnessListing';
import StaffFitnessDetail from './pages/staff/FitnessDetail';
import StaffSpecialistListing from './pages/staff/SpecialistListing';
import StaffSpecialistDetail from './pages/staff/SpecialistDetail';

// Client pages
import ClientLoginForm from './components/client/LoginForm';
import ClientRegisterForm from './components/client/RegisterForm';
import ClientForgotPasswordForm from './components/client/ForgotPasswordForm';
import ClientDashboard from './pages/client/Dashboard';
import ClientFitnessListing from './pages/client/FitnessListing';
import ClientFitnessDetail from './pages/client/FitnessDetail';
import ClientSpecialistListing from './pages/client/SpecialistListing';
import ClientSpecialistDetail from './pages/client/SpecialistDetail';

// Agent pages
import AgentLoginForm from './components/agent/LoginForm';
import AgentRegisterForm from './components/agent/RegisterForm';
import AgentForgotPasswordForm from './components/agent/ForgotPasswordForm';

import AgentDashboard from './pages/agent/Dashboard';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1f1f1f',
    },
    secondary: {
      main: '#1f1f1f',
    },
  },
  typography: {
    fontFamily:
      "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <Header />
          <main style={{ flex: 1 }}>
            <Routes>
              {/* Public routes */}
              <Route path='/' element={<Hero />} />
              <Route path='/about-us' element={<AboutUs />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='/terms-of-use' element={<TermsOfUse />} />
              <Route path='/about-fitness' element={<AboutFitness />} />
              <Route path='/about-specialist' element={<AboutSpecialist />} />
              <Route path='/login' element={<UserLoginForm />} />
              <Route path='/register' element={<UserRegisterForm />} />
              <Route
                path='/forgot-password'
                element={<UserForgotPasswordForm />}
              />
              <Route path='/staff/login' element={<StaffLoginForm />} />
              <Route path='/staff/register' element={<StaffRegisterForm />} />
              <Route
                path='/staff/forgot-password'
                element={<StaffForgotPasswordForm />}
              />
              <Route path='/client/login' element={<ClientLoginForm />} />
              <Route path='/client/register' element={<ClientRegisterForm />} />
              <Route
                path='/client/forgot-password'
                element={<ClientForgotPasswordForm />}
              />
              <Route path='/agent/login' element={<AgentLoginForm />} />
              <Route path='/agent/register' element={<AgentRegisterForm />} />
              <Route
                path='/agent/forgot-password'
                element={<AgentForgotPasswordForm />}
              />
              <Route path='/fitness-listing' element={<UserFitnessListing />} />
              <Route
                path='/fitness-listing/:id'
                element={<UserFitnessDetail />}
              />
              <Route
                path='/specialist-listing'
                element={<UserSpecialistListing />}
              />
              <Route
                path='/specialist-listing/:id'
                element={<UserSpecialistDetail />}
              />
              <Route
                path='/staff/fitness-listing'
                element={<StaffFitnessListing />}
              />
              <Route
                path='/staff/fitness-listing/:id'
                element={<StaffFitnessDetail />}
              />
              <Route
                path='/staff/specialist-listing'
                element={<StaffSpecialistListing />}
              />
              <Route
                path='/staff/specialist-listing/:id'
                element={<StaffSpecialistDetail />}
              />
              <Route
                path='/client/fitness-listing'
                element={<ClientFitnessListing />}
              />
              <Route
                path='/client/fitness-listing/:id'
                element={<ClientFitnessDetail />}
              />
              <Route
                path='/client/specialist-listing'
                element={<ClientSpecialistListing />}
              />
              <Route
                path='/client/specialist-listing/:id'
                element={<ClientSpecialistDetail />}
              />

              {/* Protected user routes */}
              <Route
                path='/dashboard'
                element={
                  <ProtectedRoute allowedRoles={['user']}>
                    <UserDashboard />
                  </ProtectedRoute>
                }
              />

              {/* Protected staff routes */}
              <Route
                path='/staff/dashboard'
                element={
                  <ProtectedRoute allowedRoles={['staff']}>
                    <StaffDashboard />
                  </ProtectedRoute>
                }
              />

              {/* Protected client routes */}
              <Route
                path='/client/dashboard'
                element={
                  <ProtectedRoute allowedRoles={['client']}>
                    <ClientDashboard />
                  </ProtectedRoute>
                }
              />

              {/* Protected agent routes */}
              <Route
                path='/agent/dashboard'
                element={
                  <ProtectedRoute allowedRoles={['agent']}>
                    <AgentDashboard />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
