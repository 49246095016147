/* eslint-disable no-console */
import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  TextField,
  InputAdornment,
  Avatar,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import specialistImage from '../../image/hero-specialistconsultation.jpeg';

const SpecialistPostings = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedPosting, setSelectedPosting] = useState(null);
  const [editFormData, setEditFormData] = useState({
    title: '',
    description: '',
    price: '',
    duration: '',
    address: '',
  });

  // Mock data - replace with API calls
  const mockPostings = [
    {
      id: 1,
      title: 'Nutrition Consultation',
      description: 'Expert nutrition and diet planning for optimal health',
      price: '$80/session',
      duration: '60 minutes',
      address: '789 Health Blvd, NY',
      bookings: 25,
      image: specialistImage,
      client: {
        name: 'Dr. Sarah Johnson',
        email: 'sarah.j@example.com',
        phone: '+1 234-567-8901',
        image: specialistImage,
        specialization: 'Nutritionist',
      },
    },
    {
      id: 2,
      title: 'Physical Therapy',
      description: 'Professional physical therapy and rehabilitation services',
      price: '$90/session',
      duration: '45 minutes',
      address: '456 Therapy St, Vancouver',
      bookings: 18,
      image: specialistImage,
      client: {
        name: 'Dr. Mike Brown',
        email: 'mike.b@example.com',
        phone: '+1 234-567-8902',
        image: specialistImage,
        specialization: 'Physical Therapist',
      },
    },
    {
      id: 3,
      title: 'Mental Health Counseling',
      description: 'Supportive mental health and wellness counseling',
      price: '$100/hour',
      duration: '50 minutes',
      address: '123 Wellness Rd, London',
      bookings: 30,
      image: specialistImage,
      client: {
        name: 'Dr. Emily White',
        email: 'emily.w@example.com',
        phone: '+1 234-567-8903',
        image: specialistImage,
        specialization: 'Mental Health',
      },
    },
  ];

  const filteredPostings = mockPostings.filter((posting) =>
    posting.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleEdit = (posting) => {
    setSelectedPosting(posting);
    setEditFormData({
      title: posting.title,
      description: posting.description,
      price: posting.price,
      duration: posting.duration || '',
      address: posting.address || '',
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (posting) => {
    setSelectedPosting(posting);
    setDeleteDialogOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Updating posting:', {
        id: selectedPosting.id,
        ...editFormData,
      });
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setEditDialogOpen(false);
      setSelectedPosting(null);
    } catch (error) {
      console.error('Error updating posting:', error);
    }
  };

  const confirmDelete = async () => {
    try {
      console.log('Deleting posting:', selectedPosting);
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setDeleteDialogOpen(false);
      setSelectedPosting(null);
    } catch (error) {
      console.error('Error deleting posting:', error);
    }
  };

  return (
    <Box>
      {/* Search and Filter */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder='Search services...'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      {/* Postings Grid */}
      <Grid container spacing={3}>
        {filteredPostings.map((posting) => (
          <Grid item xs={12} md={6} lg={4} key={posting.id}>
            <Card
              elevation={2}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 4,
                },
                position: 'relative',
              }}
            >
              {/* Action Buttons */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  display: 'flex',
                  gap: 1,
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  '.MuiCard-root:hover &': {
                    opacity: 1,
                  },
                  zIndex: 1,
                }}
              >
                <IconButton
                  size='small'
                  sx={{
                    bgcolor: 'background.paper',
                    '&:hover': { bgcolor: 'background.paper' },
                  }}
                  onClick={() => handleEdit(posting)}
                >
                  <EditIcon fontSize='small' />
                </IconButton>
                <IconButton
                  size='small'
                  sx={{
                    bgcolor: 'background.paper',
                    '&:hover': { bgcolor: 'background.paper' },
                  }}
                  onClick={() => handleDelete(posting)}
                >
                  <DeleteIcon color='error' fontSize='small' />
                </IconButton>
              </Box>
              <CardMedia
                component='img'
                image={posting.image}
                alt={posting.title}
              />
              <CardContent sx={{ flexGrow: 1, p: 3 }}>
                {/* Title and Description */}
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant='h6'
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    {posting.title}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{
                      mb: 2,
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                    }}
                  >
                    {posting.description}
                  </Typography>
                </Box>

                {/* Service Details */}
                <Box sx={{ mb: 2 }}>
                  <Typography variant='subtitle2' gutterBottom>
                    Service Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='body2' color='text.secondary'>
                        <strong>Price:</strong> {posting.price}
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        <strong>Duration:</strong> {posting.duration}
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        <strong>Address:</strong> {posting.address}
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        <strong>Bookings:</strong> {posting.bookings}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Divider sx={{ my: 2 }} />

                {/* Specialist Info */}
                <Box sx={{ mb: 2 }}>
                  <Typography variant='subtitle2' gutterBottom>
                    Specialist Information
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar
                      src={posting.client.image}
                      alt={posting.client.name}
                      sx={{
                        width: 56,
                        height: 56,
                        mr: 2,
                        border: '2px solid #1976d2',
                      }}
                    />
                    <Box>
                      <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
                        {posting.client.name}
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {posting.client.specialization}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Contact Info */}
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ mb: 0.5 }}
                  >
                    <strong>Email:</strong> {posting.client.email}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ mb: 0.5 }}
                  >
                    <strong>Phone:</strong> {posting.client.phone}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Edit Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>Edit Specialist Service</DialogTitle>
        <form onSubmit={handleEditSubmit}>
          <DialogContent>
            <Grid container spacing={3}>
              {/* Image Display */}
              <Grid item xs={12}>
                <Box sx={{ textAlign: 'center', mb: 2 }}>
                  <CardMedia
                    component='img'
                    image={selectedPosting?.image || specialistImage}
                    alt={selectedPosting?.title}
                    sx={{
                      maxHeight: 200,
                      objectFit: 'contain',
                      borderRadius: 1,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Title'
                  name='title'
                  value={editFormData.title}
                  onChange={handleEditChange}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Description'
                  name='description'
                  value={editFormData.description}
                  onChange={handleEditChange}
                  multiline
                  rows={3}
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label='Price'
                  name='price'
                  value={editFormData.price}
                  onChange={handleEditChange}
                  placeholder='e.g. $80/session'
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label='Duration'
                  name='duration'
                  value={editFormData.duration}
                  onChange={handleEditChange}
                  placeholder='e.g. 60 minutes'
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Address'
                  name='address'
                  value={editFormData.address}
                  onChange={handleEditChange}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
            <Button type='submit' variant='contained' color='primary'>
              Save Changes
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete {selectedPosting?.title}? This
            action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color='error' variant='contained'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SpecialistPostings;
