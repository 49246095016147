import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  TextField,
  IconButton,
  Badge,
  Chip,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import {
  Send as SendIcon,
  AttachFile as AttachFileIcon,
  Circle as CircleIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

const ClientMessages = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [messageAnchorEl, setMessageAnchorEl] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedText, setEditedText] = useState('');

  // Mock data - replace with API calls
  const mockChats = [
    {
      id: 1,
      staff: {
        id: 1,
        name: 'Dr. Sarah Wilson',
        avatar: null,
        status: 'online',
        role: 'Fitness Specialist',
      },
      lastMessage: {
        text: 'Your next session is scheduled for tomorrow at 2 PM.',
        timestamp: '2024-01-20T10:30:00',
        unread: true,
      },
    },
    {
      id: 2,
      staff: {
        id: 2,
        name: 'Coach Mike Brown',
        avatar: null,
        status: 'offline',
        role: 'Personal Trainer',
      },
      lastMessage: {
        text: 'Great progress today! Keep it up!',
        timestamp: '2024-01-19T15:45:00',
        unread: false,
      },
    },
    {
      id: 3,
      staff: {
        id: 3,
        name: 'Dr. Emily Chen',
        avatar: null,
        status: 'online',
        role: 'Nutritionist',
      },
      lastMessage: {
        text: 'Here is your personalized meal plan.',
        timestamp: '2024-01-19T09:15:00',
        unread: true,
      },
    },
  ];

  const mockMessages = {
    1: [
      {
        id: 1,
        sender: 'staff',
        text: 'Hi! How can I help you with your fitness journey today?',
        timestamp: '2024-01-20T10:30:00',
      },
      {
        id: 2,
        sender: 'user',
        text: 'I would like to discuss my progress.',
        timestamp: '2024-01-20T10:31:00',
      },
      {
        id: 3,
        sender: 'staff',
        text: 'Your next session is scheduled for tomorrow at 2 PM.',
        timestamp: '2024-01-20T10:32:00',
      },
    ],
    2: [
      {
        id: 1,
        sender: 'staff',
        text: 'Ready for your workout session?',
        timestamp: '2024-01-19T15:40:00',
      },
      {
        id: 2,
        sender: 'user',
        text: 'Yes, I am at the gym now.',
        timestamp: '2024-01-19T15:42:00',
      },
      {
        id: 3,
        sender: 'staff',
        text: 'Great progress today! Keep it up!',
        timestamp: '2024-01-19T15:45:00',
      },
    ],
    3: [
      {
        id: 1,
        sender: 'staff',
        text: 'Here is your personalized meal plan.',
        timestamp: '2024-01-19T09:15:00',
      },
    ],
  };

  const handleSendMessage = () => {
    if (!newMessage.trim() || !selectedChat) return;

    // Add message to the chat
    const newMsg = {
      id: mockMessages[selectedChat.id].length + 1,
      sender: 'user',
      text: newMessage,
      timestamp: new Date().toISOString(),
    };

    mockMessages[selectedChat.id].push(newMsg);
    setNewMessage('');
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handleMessageAction = (message, event) => {
    setSelectedMessage(message);
    setMessageAnchorEl(event.currentTarget);
  };

  const handleCloseMessageMenu = () => {
    setMessageAnchorEl(null);
  };

  const handleEditMessage = () => {
    setEditedText(selectedMessage.text);
    setEditDialogOpen(true);
    handleCloseMessageMenu();
  };

  const handleDeleteMessage = () => {
    setDeleteDialogOpen(true);
    handleCloseMessageMenu();
  };

  const confirmEdit = () => {
    if (!editedText.trim()) return;

    const messageIndex = mockMessages[selectedChat.id].findIndex(
      (msg) => msg.id === selectedMessage.id
    );

    if (messageIndex !== -1) {
      mockMessages[selectedChat.id][messageIndex].text = editedText;
      mockMessages[selectedChat.id][messageIndex].edited = true;
    }

    setEditDialogOpen(false);
    setSelectedMessage(null);
    setEditedText('');
  };

  const confirmDelete = () => {
    const updatedMessages = mockMessages[selectedChat.id].filter(
      (msg) => msg.id !== selectedMessage.id
    );
    mockMessages[selectedChat.id] = updatedMessages;

    setDeleteDialogOpen(false);
    setSelectedMessage(null);
  };

  return (
    <Paper sx={{ display: 'flex' }}>
      {/* Chat List */}
      <Box
        sx={{
          width: 320,
          borderRight: 1,
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <List sx={{ flex: 1, overflow: 'auto' }}>
          {mockChats.map((chat) => (
            <React.Fragment key={chat.id}>
              <ListItem
                button
                selected={selectedChat?.id === chat.id}
                onClick={() => setSelectedChat(chat)}
              >
                <ListItemAvatar>
                  <Badge
                    overlap='circular'
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <CircleIcon
                        sx={{
                          fontSize: 12,
                          color:
                            chat.staff.status === 'online'
                              ? 'success.main'
                              : 'grey.500',
                          backgroundColor: 'background.paper',
                          borderRadius: '50%',
                        }}
                      />
                    }
                  >
                    <Avatar alt={chat.staff.name}>
                      {chat.staff.name.charAt(0)}
                    </Avatar>
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Box>
                        <Typography variant='subtitle2'>
                          {chat.staff.name}
                        </Typography>
                        <Typography
                          variant='caption'
                          color='text.secondary'
                          component='div'
                        >
                          {chat.staff.role}
                        </Typography>
                      </Box>
                      <Typography variant='caption' color='text.secondary'>
                        {formatTimestamp(chat.lastMessage.timestamp)}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Typography
                      variant='body2'
                      color={
                        chat.lastMessage.unread
                          ? 'text.primary'
                          : 'text.secondary'
                      }
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {chat.lastMessage.text}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider component='li' />
            </React.Fragment>
          ))}
        </List>
      </Box>

      {/* Chat Content */}
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {selectedChat ? (
          <>
            {/* Chat Header */}
            <Box
              sx={{
                p: 2,
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Avatar alt={selectedChat.staff.name}>
                {selectedChat.staff.name.charAt(0)}
              </Avatar>
              <Box sx={{ ml: 2 }}>
                <Typography variant='subtitle1'>
                  {selectedChat.staff.name}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {selectedChat.staff.role}
                </Typography>
              </Box>
              <Chip
                size='small'
                label={selectedChat.staff.status}
                color={
                  selectedChat.staff.status === 'online' ? 'success' : 'default'
                }
                sx={{ ml: 'auto' }}
              />
            </Box>

            {/* Messages */}
            <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>
              {mockMessages[selectedChat.id].map((message) => (
                <Box
                  key={message.id}
                  sx={{
                    display: 'flex',
                    justifyContent:
                      message.sender === 'user' ? 'flex-end' : 'flex-start',
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: '70%',
                      backgroundColor:
                        message.sender === 'user' ? 'primary.main' : 'grey.100',
                      color:
                        message.sender === 'user' ? 'white' : 'text.primary',
                      borderRadius: 2,
                      p: 1.5,
                      position: 'relative',
                    }}
                  >
                    {message.sender === 'user' && (
                      <IconButton
                        size='small'
                        sx={{
                          position: 'absolute',
                          left: -32,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          color: 'text.secondary',
                        }}
                        onClick={(e) => handleMessageAction(message, e)}
                      >
                        <MoreVertIcon fontSize='small' />
                      </IconButton>
                    )}
                    <Typography variant='body1'>{message.text}</Typography>
                    <Typography
                      variant='caption'
                      sx={{
                        display: 'block',
                        textAlign: message.sender === 'user' ? 'right' : 'left',
                        mt: 0.5,
                        opacity: 0.8,
                      }}
                    >
                      {formatTimestamp(message.timestamp)}
                      {message.edited && ' (edited)'}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>

            {/* Message Input */}
            <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton size='small'>
                  <AttachFileIcon />
                </IconButton>
                <TextField
                  fullWidth
                  size='small'
                  placeholder='Type a message...'
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSendMessage();
                    }
                  }}
                />
                <IconButton
                  color='primary'
                  onClick={handleSendMessage}
                  disabled={!newMessage.trim()}
                >
                  <SendIcon />
                </IconButton>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='body1' color='text.secondary'>
              Select a conversation to start messaging
            </Typography>
          </Box>
        )}
      </Box>

      {/* Message Actions Menu */}
      <Menu
        anchorEl={messageAnchorEl}
        open={Boolean(messageAnchorEl)}
        onClose={handleCloseMessageMenu}
      >
        <MenuItem onClick={handleEditMessage}>
          <EditIcon fontSize='small' sx={{ mr: 1 }} />
          Edit Message
        </MenuItem>
        <MenuItem onClick={handleDeleteMessage} sx={{ color: 'error.main' }}>
          <DeleteIcon fontSize='small' sx={{ mr: 1 }} />
          Delete Message
        </MenuItem>
      </Menu>

      {/* Edit Message Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>Edit Message</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={3}
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmEdit} variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Message Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Message</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this message?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color='error' variant='contained'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ClientMessages;
