import React, { useState } from 'react';
import {
  Box,
  TextField,
  Paper,
  Avatar,
  Typography,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@mui/material';
import { Search } from '@mui/icons-material';

const UserManagement = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const mockUsers = [
    {
      id: 1,
      name: 'John Doe',
      email: 'john@example.com',
      joinDate: '2023-01-15',
    },
    {
      id: 2,
      name: 'Jane Smith',
      email: 'jane@example.com',
      joinDate: '2023-01-15',
    },
    {
      id: 3,
      name: 'Alice Johnson',
      email: 'alice@example.com',
      joinDate: '2023-01-15',
    },
    {
      id: 4,
      name: 'Bob Brown',
      email: 'bob@example.com',
      joinDate: '2023-01-15',
    },
    {
      id: 5,
      name: 'Charlie Davis',
      email: 'charlie@example.com',
      joinDate: '2023-01-15',
    },
  ];

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = mockUsers.filter(
    (user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box>
      <Paper sx={{ p: 3, mb: 3 }}>
        <TextField
          fullWidth
          variant='outlined'
          placeholder='Search accounts by name or email...'
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Paper>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Join Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id} hover>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Avatar src={user.avatar}>{user.name.charAt(0)}</Avatar>
                    <Box>
                      <Typography variant='subtitle2'>{user.name}</Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {user.email}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  {new Date(user.joinDate).toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserManagement;
