import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  Avatar,
  Chip,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  DialogContentText,
} from '@mui/material';
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import fitnessImage from '../../image/hero-fitness.jpeg';

const FitnessClients = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [editFormData, setEditFormData] = useState({
    title: '',
    description: '',
    commissionRate: '',
    yearsOfExperience: '',
    activeClients: '',
    specialties: [],
    newSpecialty: '', // For adding new specialties
  });

  // Mock data - replace with API calls
  const mockClients = [
    {
      id: 1,
      name: 'John Smith',
      email: 'john.smith@example.com',
      phone: '+1 234-567-8900',
      title: 'Professional Fitness Trainer',
      description:
        'Experienced personal trainer specializing in strength training and weight loss programs.',
      image: fitnessImage,
      commissionRate: 20,
      location: 'New York, USA',
      specialties: ['Weight Training', 'HIIT', 'Nutrition Planning'],
      yearsOfExperience: 5,
      activeClients: 12,
    },
    {
      id: 2,
      name: 'Sarah Johnson',
      email: 'sarah.j@example.com',
      phone: '+1 234-567-8901',
      title: 'Yoga and Wellness Expert',
      description:
        'Certified yoga instructor with focus on mindfulness and holistic wellness approaches.',
      image: fitnessImage,
      commissionRate: 15,
      location: 'Los Angeles, USA',
      specialties: ['Yoga', 'Meditation', 'Stress Management'],
      yearsOfExperience: 8,
      activeClients: 15,
    },
    {
      id: 3,
      name: 'Mike Wilson',
      email: 'mike.w@example.com',
      phone: '+1 234-567-8902',
      title: 'Sports Performance Coach',
      description:
        'Former athlete turned fitness coach, specializing in sports-specific training and conditioning.',
      image: fitnessImage,
      commissionRate: 25,
      location: 'Chicago, USA',
      specialties: [
        'Sports Training',
        'Athletic Performance',
        'Injury Prevention',
      ],
      yearsOfExperience: 6,
      activeClients: 10,
    },
    {
      id: 4,
      name: 'Emma Davis',
      email: 'emma.d@example.com',
      phone: '+1 234-567-8903',
      title: 'Dance Fitness Instructor',
      description:
        'Professional dancer specializing in dance-based workouts and cardio programs.',
      image: fitnessImage,
      commissionRate: 18,
      location: 'Miami, USA',
      specialties: ['Zumba', 'Dance Cardio', 'Flexibility Training'],
      yearsOfExperience: 4,
      activeClients: 20,
    },
    {
      id: 5,
      name: 'David Chen',
      email: 'david.c@example.com',
      phone: '+1 234-567-8904',
      title: 'Senior Fitness Specialist',
      description:
        'Certified specialist in senior fitness and rehabilitation exercises.',
      image: fitnessImage,
      commissionRate: 22,
      location: 'Seattle, USA',
      specialties: ['Senior Fitness', 'Low Impact Training', 'Balance Work'],
      yearsOfExperience: 10,
      activeClients: 8,
    },
    {
      id: 6,
      name: 'Lisa Martinez',
      email: 'lisa.m@example.com',
      phone: '+1 234-567-8905',
      title: 'CrossFit Coach',
      description:
        'CrossFit Level 2 trainer specializing in high-intensity functional training.',
      image: fitnessImage,
      commissionRate: 28,
      location: 'Austin, USA',
      specialties: ['CrossFit', 'Olympic Lifting', 'Functional Training'],
      yearsOfExperience: 7,
      activeClients: 16,
    },
  ];

  const filteredClients = mockClients.filter((client) =>
    client.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleEditClick = (client) => {
    setSelectedClient(client);
    setEditFormData({
      title: client.title,
      description: client.description,
      commissionRate: client.commissionRate,
      yearsOfExperience: client.yearsOfExperience,
      activeClients: client.activeClients,
      specialties: [...client.specialties],
      newSpecialty: '',
    });
    setEditDialogOpen(true);
  };

  const handleDeleteClick = (client) => {
    setSelectedClient(client);
    setDeleteDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setSelectedClient(null);
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
    setSelectedClient(null);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddSpecialty = () => {
    if (editFormData.newSpecialty.trim() !== '') {
      setEditFormData((prev) => ({
        ...prev,
        specialties: [...prev.specialties, prev.newSpecialty.trim()],
        newSpecialty: '',
      }));
    }
  };

  const handleRemoveSpecialty = (index) => {
    setEditFormData((prev) => ({
      ...prev,
      specialties: prev.specialties.filter((_, i) => i !== index),
    }));
  };

  const handleEditSubmit = async () => {
    try {
      // eslint-disable-next-line no-console
      console.log('Updating client:', {
        id: selectedClient.id,
        ...editFormData,
      });
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      handleEditClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating client:', error);
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      // eslint-disable-next-line no-console
      console.log('Deleting client:', selectedClient);
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      handleDeleteClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error deleting client:', error);
    }
  };

  return (
    <Box>
      {/* Header and Search */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder='Search clients...'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      {/* Clients Grid */}
      <Grid container spacing={3}>
        {filteredClients.map((client) => (
          <Grid item xs={12} md={6} lg={4} key={client.id}>
            <Card
              elevation={2}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 4,
                },
                position: 'relative',
              }}
            >
              {/* Action Buttons - Positioned absolutely */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  display: 'flex',
                  gap: 1,
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  '.MuiCard-root:hover &': {
                    opacity: 1,
                  },
                  zIndex: 1,
                }}
              >
                <IconButton
                  size='small'
                  sx={{
                    bgcolor: 'background.paper',
                    '&:hover': { bgcolor: 'background.paper' },
                  }}
                  onClick={() => handleEditClick(client)}
                >
                  <EditIcon fontSize='small' />
                </IconButton>
                <IconButton
                  size='small'
                  sx={{
                    bgcolor: 'background.paper',
                    '&:hover': { bgcolor: 'background.paper' },
                  }}
                  onClick={() => handleDeleteClick(client)}
                >
                  <DeleteIcon color='error' fontSize='small' />
                </IconButton>
              </Box>

              <CardContent sx={{ flexGrow: 1, p: 3 }}>
                {/* Title and Description */}
                <Typography variant='h6' gutterBottom sx={{ fontWeight: 600 }}>
                  {client.title}
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    mb: 2,
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    height: '40px',
                  }}
                >
                  {client.description}
                </Typography>

                <Typography
                  variant='caption'
                  color='primary'
                  sx={{ fontWeight: 'bold' }}
                >
                  Commission: {client.commissionRate}%
                </Typography>
                <Divider sx={{ my: 2 }} />

                {/* Profile Info */}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar
                    src={client.image}
                    alt={client.name}
                    sx={{
                      width: 56,
                      height: 56,
                      mr: 2,
                      border: '2px solid #1976d2',
                    }}
                  />
                  <Box>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
                      {client.name}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {client.location}
                    </Typography>
                  </Box>
                </Box>

                {/* Contact Info */}
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ mb: 0.5 }}
                  >
                    <strong>Email:</strong> {client.email}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ mb: 0.5 }}
                  >
                    <strong>Phone:</strong> {client.phone}
                  </Typography>
                </Box>

                {/* Stats */}
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <Box
                    sx={{
                      flex: 1,
                      textAlign: 'center',
                      p: 1,
                      bgcolor: 'grey.50',
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant='h6' color='primary'>
                      {client.yearsOfExperience}
                    </Typography>
                    <Typography variant='caption' color='text.secondary'>
                      Years Exp.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      textAlign: 'center',
                      p: 1,
                      bgcolor: 'grey.50',
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant='h6' color='primary'>
                      {client.activeClients}
                    </Typography>
                    <Typography variant='caption' color='text.secondary'>
                      Active Clients
                    </Typography>
                  </Box>
                </Box>

                {/* Specialties */}
                <Box>
                  <Typography variant='subtitle2' gutterBottom>
                    Specialties
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {client.specialties.map((specialty, index) => (
                      <Chip
                        key={index}
                        label={specialty}
                        size='small'
                        variant='outlined'
                        sx={{ borderRadius: 1 }}
                      />
                    ))}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Edit Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Edit FitnessClient</DialogTitle>
        <DialogContent dividers>
          <Stack spacing={3} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              label='Title'
              name='title'
              value={editFormData.title}
              onChange={handleEditChange}
            />
            <TextField
              fullWidth
              label='Description'
              name='description'
              value={editFormData.description}
              onChange={handleEditChange}
              multiline
              rows={3}
            />
            <TextField
              fullWidth
              label='Commission Rate (%)'
              name='commissionRate'
              type='number'
              value={editFormData.commissionRate}
              onChange={handleEditChange}
              InputProps={{
                inputProps: { min: 0, max: 100 },
              }}
            />
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Years of Experience'
                name='yearsOfExperience'
                type='number'
                value={editFormData.yearsOfExperience}
                onChange={handleEditChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Active Clients'
                name='activeClients'
                type='number'
                value={editFormData.activeClients}
                onChange={handleEditChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>

            <Box>
              <Typography variant='subtitle2' gutterBottom>
                Specialties
              </Typography>
              <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {editFormData.specialties.map((specialty, index) => (
                  <Chip
                    key={index}
                    label={specialty}
                    onDelete={() => handleRemoveSpecialty(index)}
                    size='small'
                  />
                ))}
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  fullWidth
                  size='small'
                  label='Add Specialty'
                  name='newSpecialty'
                  value={editFormData.newSpecialty}
                  onChange={handleEditChange}
                />
                <Button
                  variant='outlined'
                  onClick={handleAddSpecialty}
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSubmit} variant='contained'>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteClose}
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Fitness Client? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button
            onClick={handleDeleteSubmit}
            color='error'
            variant='contained'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FitnessClients;
