import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  Chip,
  Alert,
  CircularProgress,
  Stack,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

const CreateSpecialistClient = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    commissionRate: '',
    yearsOfExperience: '',
    activeClients: '',
    specialties: [],
    newSpecialty: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError(null);
  };

  const handleAddSpecialty = () => {
    if (formData.newSpecialty.trim() !== '') {
      setFormData((prev) => ({
        ...prev,
        specialties: [...prev.specialties, formData.newSpecialty.trim()],
        newSpecialty: '',
      }));
    }
  };

  const handleRemoveSpecialty = (index) => {
    setFormData((prev) => ({
      ...prev,
      specialties: prev.specialties.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    const errors = [];
    if (!formData.title) errors.push('Title is required');
    if (!formData.description) errors.push('Description is required');
    if (!formData.commissionRate) errors.push('Commission rate is required');
    if (!formData.yearsOfExperience)
      errors.push('Years of experience is required');
    if (!formData.activeClients) errors.push('Active clients is required');
    if (formData.specialties.length === 0)
      errors.push('At least one specialty is required');

    if (errors.length > 0) {
      setError(errors.join('. '));
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // eslint-disable-next-line no-console
      console.log('Creating specialist client:', formData);
      // Reset form after successful submission
      setFormData({
        title: '',
        description: '',
        commissionRate: '',
        yearsOfExperience: '',
        activeClients: '',
        specialties: [],
        newSpecialty: '',
      });
    } catch (err) {
      setError('Failed to create client. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant='h5' sx={{ mb: 4, fontWeight: 600 }}>
        Create Specialist Client
      </Typography>

      {error && (
        <Alert severity='error' sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label='Title'
            name='title'
            value={formData.title}
            onChange={handleChange}
            disabled={loading}
            required
          />

          <TextField
            fullWidth
            label='Description'
            name='description'
            value={formData.description}
            onChange={handleChange}
            multiline
            rows={3}
            disabled={loading}
            required
          />

          <Grid container>
            <Grid item xs={12} sm={4} sx={{ pr: { sm: 1 } }}>
              <TextField
                fullWidth
                label='Commission Rate (%)'
                name='commissionRate'
                type='number'
                value={formData.commissionRate}
                onChange={handleChange}
                disabled={loading}
                required
                inputProps={{ min: 0, max: 100 }}
              />
            </Grid>

            <Grid item xs={12} sm={4} sx={{ px: { sm: 1 } }}>
              <TextField
                fullWidth
                label='Years of Experience'
                name='yearsOfExperience'
                type='number'
                value={formData.yearsOfExperience}
                onChange={handleChange}
                disabled={loading}
                required
                inputProps={{ min: 0 }}
              />
            </Grid>

            <Grid item xs={12} sm={4} sx={{ pl: { sm: 1 } }}>
              <TextField
                fullWidth
                label='Active Clients'
                name='activeClients'
                type='number'
                value={formData.activeClients}
                onChange={handleChange}
                disabled={loading}
                required
                inputProps={{ min: 0 }}
              />
            </Grid>
          </Grid>

          <Box>
            <Typography variant='subtitle2' gutterBottom>
              Specialties
            </Typography>
            <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {formData.specialties.map((specialty, index) => (
                <Chip
                  key={index}
                  label={specialty}
                  onDelete={() => handleRemoveSpecialty(index)}
                  size='small'
                />
              ))}
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                fullWidth
                size='small'
                label='Add Specialty'
                name='newSpecialty'
                value={formData.newSpecialty}
                onChange={handleChange}
                disabled={loading}
              />
              <Button
                variant='outlined'
                onClick={handleAddSpecialty}
                startIcon={<AddIcon />}
                disabled={loading || !formData.newSpecialty.trim()}
              >
                Add
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              disabled={loading}
              sx={{ minWidth: 120, height: 40 }}
            >
              {loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Create Client'
              )}
            </Button>
          </Box>
        </Stack>
      </form>
    </Paper>
  );
};

export default CreateSpecialistClient;
