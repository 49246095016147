import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Tabs,
  Tab,
  Avatar,
} from '@mui/material';
import {
  Person,
  FitnessCenter,
  Psychology,
  Message,
  Add,
  Group,
} from '@mui/icons-material';
import useAuthStore from '../../stores/useAuthStore';
import ClientProfile from '../../components/client/ClientProfile';
import ClientMessages from '../../components/client/ClientMessages';
import FitnessServices from '../../components/client/FitnessServices';
import CreateFitnessService from '../../components/client/CreateFitnessService';
import SpecialistServices from '../../components/client/SpecialistServices';
import CreateSpecialistService from '../../components/client/CreateSpecialistService';
import AccountManagement from '../../components/client/AccountManagement';

const ClientDashboardPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { user } = useAuthStore();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return <ClientProfile />;
      case 1:
        return <ClientMessages />;
      case 2:
        return <AccountManagement />;
      case 3:
        return <FitnessServices />;
      case 4:
        return <CreateFitnessService />;
      case 5:
        return <SpecialistServices />;
      case 6:
        return <CreateSpecialistService />;
      default:
        return <ClientProfile />;
    }
  };

  return (
    <Box sx={{ minHeight: 'calc(100vh - 64px)', bgcolor: '#f5f5f5', py: 4 }}>
      <Container maxWidth='lg'>
        <Grid container spacing={3}>
          {/* Client Info Header */}
          <Grid item xs={12}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Avatar
                  sx={{ width: 64, height: 64, bgcolor: 'secondary.main' }}
                >
                  {user?.name?.charAt(0) || 'C'}
                </Avatar>
                <Box>
                  <Typography variant='h5' sx={{ fontWeight: 600 }}>
                    {user?.name || 'Client'}
                  </Typography>
                  <Typography variant='body1' color='text.secondary'>
                    {user?.email}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Navigation Tabs */}
          <Grid item xs={12}>
            <Paper sx={{ mb: 3 }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant='scrollable'
                scrollButtons='auto'
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <Tab icon={<Person />} label='Profile' />
                <Tab icon={<Message />} label='Messages' />
                <Tab icon={<Group />} label='Account Management' />
                <Tab icon={<FitnessCenter />} label='Fitness Services' />
                <Tab icon={<Add />} label='Create Fitness Service' />
                <Tab icon={<Psychology />} label='Specialist Services' />
                <Tab icon={<Add />} label='Create Specialist Service' />
              </Tabs>
            </Paper>
          </Grid>

          {/* Content Area */}
          <Grid item xs={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ClientDashboardPage;
