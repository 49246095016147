import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuthStore from '../../stores/useAuthStore';

const ProtectedRoute = ({ children, allowedRoles = ['user', 'staff'] }) => {
  const { isAuthenticated, user } = useAuthStore();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to='/' state={{ from: location }} replace />;
  }

  if (!allowedRoles.includes(user?.role)) {
    switch (user?.role) {
      case 'staff':
        return <Navigate to='/staff/dashboard' replace />;
      case 'client':
        return <Navigate to='/client/dashboard' replace />;
      case 'agent':
        return <Navigate to='/agent/dashboard' replace />;
      default:
        return <Navigate to='/dashboard' replace />;
    }
  }

  return children;
};

export default ProtectedRoute;
